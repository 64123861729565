@import '../../style/variables';

.loginPage {
  height: auto;
  display: flex;
  .logo {
    margin-bottom: 3rem;
    width: 220px;
    height: 86px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 992px) {
      margin-bottom: 4rem;
    }
  }

  .logoWide {
    margin-bottom: 1rem;
    width: 330px;
    height: 86px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 992px) {
      margin-bottom: 2rem;
    }
  }

  .leftSideWrapper {
    width: 100%;
  }
}

.leftSide {
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh; /* ie 11 */
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;
  color: #999999;

  a {
    color: #999999;
  }

  a:hover {
    color: #dcdcdc;
  }

  @media (min-width: $tabletBreakpoint) {
    flex-basis: 50%;
  }

  @media (min-width: $largeMonitorBreakpoint) {
    flex-basis: 560px;
  }
}

.rightSide {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background: $alabaster;

  @media (max-width: $tabletBreakpoint) {
    display: none;
  }
}
