@import '../../style/variables';

:global(.ui.form .field).field {
  margin-bottom: 10px;
}

.input {
  width: 100%;
  height: 44px;
}

.checkbox {
  margin: 5px 0 20px;
}

:global(.ui.button).createBtn {
  margin: 40px 0 0 0;
}

.productName {
  text-transform: capitalize;
}
