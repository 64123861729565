@import '../../style/variables';

.wrapper {
  padding-bottom: 0;

  .headerHolder {
    display: flex;
    align-items: center;

    .header {
      margin-left: 20px;

      .headerText {
        color: #666666;
        font-family: 'Open Sans';
        font-size: 15px;
        font-weight: 600;
        text-align: left;

        .email {
          color: $primaryLinkColor;
        }
      }

      .headerSubTitle {
        color: #9b9b9b;
        font-family: 'Open sans';
        font-size: 12px;
        font-weight: 600;
        text-align: left;
      }
    }
  }

  .actionButtonHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 43px 0;

    & > * {
      margin-top: 20px;
    }

    & > *:first-child {
      margin-top: 0;
    }
  }
}
