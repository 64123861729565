@import '../../style/variables';

.logo {
  width: 230px;
  margin-bottom: 50px;
}

.wrapper :global(> p) {
  padding-bottom: 16px;
  font-size: 16px;
}

.wrapper {
  text-align: center;
  width: 100%;

  :global {
    p {
      &.lg {
        font-size: 20px;
      }
    }

    .form {
      text-align: left;
    }

    h1.warning {
      color: $primaryColor;
      font-size: 55px;
      font-weight: normal;
      margin: 0 0 25px;

      &.xl {
        font-size: 120px;
      }
    }
  }
}

.link {
  display: block;
}
