.initials {
  width: 78px;
  height: 78px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  color: #666666;
  font-family: 'Open sans';
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
