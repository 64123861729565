@import '../../../style/variables';

:global(h3) {
  font-size: 18px !important;
}

.gridWrapper {
  max-width: 460px;

  @media (min-width: $largeMonitorBreakpoint) {
    max-width: 800px;
  }
}

.description {
  color: #a1a1a1;
  margin-top: 4px;
}

.registeredTrademark {
  vertical-align: super;
  font-size: 12px;
}

.image {
  align-self: center;
  margin: 24px 0px;
  max-width: 100% !important;

  @media (min-width: $largeMonitorBreakpoint) {
    margin: 48px 0px;
    max-width: 60vh !important;
  }

  @media (min-width: $widescreenMonitorBreakpoint) {
    max-width: 100% !important;
  }
}

.link {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: $primaryLinkColor;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.productContent {
  flex-basis: 169px;
  margin-top: 20px !important;

  @media (min-width: $computerBreakpoint) {
    flex-basis: auto;
    margin-top: 0px;
  }
}

.productContent_productName {
  height: 28px;
  vertical-align: bottom;
  display: table-cell;
}

.noPadding {
  padding: 0px !important;
}

.noMargin {
  margin: 0px !important;
}
