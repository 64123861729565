@import '../../style/variables';

.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  background: $alabaster;
  min-height: 100vh;
  align-items: baseline;

  @media (min-width: $tabletBreakpoint) {
    align-items: center;
  }
}

$padding: 30px;
$_paddingStep: 20px;
$_baseWidth: 420px;

$paddingTablet: $padding + $_paddingStep;
$paddingComputer: $paddingTablet + $_paddingStep;

$width: $_baseWidth + $padding * 2;
$widthTablet: $_baseWidth + $paddingTablet * 2;
$widthComputer: $_baseWidth + $paddingComputer * 2;

.inner {
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 560px;
  padding: 20px $padding;

  @media (min-width: $mobileBreakpoint) {
    width: $width;
    padding: 40px $padding;
  }

  @media (min-width: $width) {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    margin-top: 20px;
  }

  @media (min-width: $tabletBreakpoint) {
    justify-content: center;
    padding: 60px $paddingTablet;
    width: $widthTablet;
  }

  @media (min-width: $computerBreakpoint) {
    padding: 80px $paddingComputer;
    width: $widthComputer;
  }
}
