@import '../../style/variables';

:global .pingdom .sidebar {
  background-color: $loginLeftSideColor;
}

.loginLeftSideBackground {
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url('./assets/pingdom-pattern.svg');
  background-repeat: no-repeat;
  background-position: -356px center;
}
