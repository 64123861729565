// Breakpoints
$mobileBreakpoint: 320px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1680px;

// Colors
$primaryColor: #0079aa;
$primaryLinkColor: #4183c4;
$white: #fff;
$loginLeftSideColor: #222222;

$alabaster: #fafafa;
$athensGrey: #e0e1e2;
$steel: #666;
