@import '../../style/variables';

:global .sidebar {
  background-color: $loginLeftSideColor;
}

.loginLeftSideBackground {
  position: absolute !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url('assets/swo-pattern.svg') no-repeat -356px center;
}
