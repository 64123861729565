:global(.ui.error.message).errorMessage {
  margin-bottom: 26px;
  height: 44px;
  line-height: 0.85em;
}

:global(.ui.grid > .column:not(.row)).resetButton {
  margin-top: 5px;
  padding-bottom: 0;
}
