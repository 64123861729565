.samlLoginForm {
  &:global(.ui.form) {
    max-width: 420px;
    margin: 0 auto;
  }
}

.loginButton {
  margin-top: 0.5rem;
}

.emailInput {
  margin-bottom: 59px;
}
