.errorMessage {
  margin-bottom: 26px !important;
}

.emailError {
  margin-top: 0 !important;
}

.forgotPassword {
  text-align: right;
}

.loginButton {
  margin-top: 0.5rem;
}
