@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');
@import '../../style/variables';

.logo {
  width: 230px;
  margin-left: 20px;
}

.brandLine {
  width: 100%;
  height: 5px;
  background-image: url('../../themes/shared/assets/multi-colored-brand-line.svg');
  background-repeat: repeat-x;
  margin-top: 20px;
}

.wider {
  padding: 0 10px;
  min-height: 520px;

  > div {
    min-height: 500px;
  }
}

.wrapper {
  padding: 30px 60px 60px 80px;
  text-align: center;

  input[type='submit'] {
    color: #04c9d7;
  }

  h1 {
    font-weight: bold;
    font-size: 24px;
    font-family: Roboto, 'Open Sans', sans-serif;
    color: #04c9d7;
  }

  p {
    text-align: left;
    font-size: 16px;
    font-family: Roboto, 'Open Sans', sans-serif;
    color: #444444;
  }
}
