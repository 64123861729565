#root {
  min-height: 100vh;
  height: 100%;
  font-family: 'Open Sans', Helvetica, sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
