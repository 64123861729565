@import '../../style/variables';
.mfaForm {
  &:global(.ui.form) {
    max-width: 420px;
    margin: 0 auto;
  }
}

.twoFactorText {
  margin-bottom: 25px;
}

.loginButton {
  margin-top: 0.5rem;
}

.codeInput {
  margin-bottom: 18px;
}

.modalIcon {
  padding: 2px 20px 0 0;
}

.modalInfo {
  display: flex;
  margin-bottom: 20px;
}

.modalContent {
  font-size: 13px !important;
}

.instructions {
  margin: 15px 0 0 30px;
}

.linkColor {
  color: $primaryLinkColor;
}

.accordionTitle {
  width: fit-content;
}

.spanLink {
  color: $primaryLinkColor;
  cursor: pointer;
  width: 35px;
  align-self: center;

  &.copied {
    color: green;
  }
}

.downloadLinksContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 10px;
}

.link {
  color: $primaryLinkColor;
  padding-left: 10px;
  padding-right: 30px;
  @media (max-width: $tabletBreakpoint) {
    width: 100%;
  }
}

.downloadLinks {
  max-width: 500px;
}

.imageContent {
  margin: 15px 0;
  height: 150px;

  img {
    margin-left: 15px;
  }
}

.accordionContent {
  padding-bottom: 10px;
  p {
    padding: 0 10px;
  }
  .secretKey {
    height: 50px;
    background: $athensGrey;
    border: 1px solid $primaryLinkColor;
    padding: 17px;
    display: flex;
  }
}

.osTitle {
  width: 55px;
}

.qrSecret {
  flex: 1;
  @media (max-width: $tabletBreakpoint) {
    font-size: 10px !important;
  }
}

.checkboxContainer {
  padding-left: 13px;
}

.checkboxText {
  top: 3px;
  right: 10px;
}

.checkboxLabel {
  font-weight: bold;
  color: #767676 !important;
}
