@import '../../style/variables';

.links {
  margin: 30px auto 0;
  width: 360px;
}

.link {
  background-color: $white;
  border-radius: 4px;
  border: solid 1px $athensGrey;
  box-shadow: 0 0 0 0 transparent;
  display: flex;
  height: 75px;
  line-height: 75px;
  margin-bottom: 20px;
  transition-duration: 0.3s;
  transition-property: box-shadow, border;
  transition-timing-function: ease-in-out;
  vertical-align: middle;

  &:hover {
    background-color: $white;
    border-radius: 4px;
    border: solid 1px $primaryColor;
    box-shadow: 0 1px 3px 0 transparentize($primaryColor, 0.5);
    cursor: pointer;
  }

  & img {
    margin: auto;
    max-height: 39%;
  }
}
