.crossProductNote {
  display: inline-block;
  text-align: justify;
  width: 367px;
  padding: 30px 0px;
  hyphens: auto;
}

.noWrap {
  white-space: nowrap;
}
