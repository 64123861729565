@import '../../style/variables';

:global .appoptics .sidebar {
  background: $loginLeftSideColor;
}

.loginLeftSideBackground {
  position: absolute !important;
  height: 100%;
  width: 300px;
  opacity: 0.6;
  top: 0;
  left: 0;
  background: url('./assets/appoptics-pattern.svg');
  background-repeat: repeat-y;
}
