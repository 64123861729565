.container {
  margin-top: 15px;
  margin-bottom: 35px;
}

.ssoLink,
.ssoLink:hover {
  text-decoration: none;
}

#samlButton {
  box-shadow: inset 0 0 0 2px #6f6f6f;
  color: #6f6f6f;
}

#samlButton:hover {
  box-shadow: inset 0 0 0 2px #fff;
  color: #000;
}
