@import '../../../style/variables';

.actionButtonBox {
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #e0e1e2;
  height: 75px;
  width: 370px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  text-align: left;
  font-family: 'Open sans';
  font-weight: 600;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  .actionButtonContentHolder {
    padding: 16px;
    letter-spacing: 0;
    line-height: 20px;

    .mainText {
      color: $primaryLinkColor;
      font-size: 16px;
    }

    .descriptionText {
      color: #9b9b9b;
      font-size: 12px;
    }
  }

  i {
    color: $athensGrey;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid $primaryColor;
    box-shadow: 0 1px 3px 0 transparentize($primaryColor, 0.5);
  }
}
